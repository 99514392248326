.contactTitle {
    font-family: "Product Sans", sans-serif;
    font-size: 5vw;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.contactWelcome {
    text-align: center;
  }
  
  .contactForm {
    max-width: 500px;
    margin: 0 auto;
    
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    font-weight: normal;
  }
  
  input,
  textarea {
    width: 100%;
    height: 40px;
    padding: 0.5rem;
    border: none;
    background-color: #F6F8FA;
    border-radius: 15px;
  }
  
  .submit-button {
    width: 100px;
    height: 40px;
    background-color: #000000;
    color: #fff;
    font-size: 15px;
    padding: 0.5rem 1rem;
    border-radius: 100px;
    border: none;
  }

  @media (max-width: 767px) {
    .contactForm {
      max-width: 80%;
    }
}