* {
    font-family: "Product Sans", sans-serif;
}
.appBody {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

.welcomeSection h1 {
    font-size: 50px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: linear-gradient(45deg, #22D45A, #2247D4);
}

.animated {
    width: 1000px;
    height: auto;
    position: relative;
    z-index: 1;
}

.textSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1300px;
    height: auto;
    border-radius: 45px;
    background-color: white;
    position: relative;
    top: -100px;
    z-index: 2;
}

.welcomeTitle {
    font-size: 55px;
    font-family: "Product Sans", sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
}

.discoverButton {
    background-color: black;
    color: white;
    width: 150px;
    height: 50px;
    border-radius: 100px;
    font-family: 'Product Sans', sans-serif;
}