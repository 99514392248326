@import url('https://fonts.cdnfonts.com/css/product-sans');

.navBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 0px;
    background-color: #ffffff;
    border-radius: 100px;
    margin: 25px;
    /*box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);*/
}

.navLogo {
    width: 45px;
    height: auto;
    margin-left: 10px;
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.links a {
    margin-left: 20px;
    font-family: "Product Sans", sans-serif, Arial, Helvetica, sans-serif;
    color: black;
    text-decoration: none;
    text-shadow: none;
}

.contactButton {
    color: white !important;
    background-color: black;
    border-radius: 100px;
    padding: 10px 20px; /* Ajusta el espaciado interno */
    width: 120px;
    height: 50px; /* Fija la altura para que el centrado sea consistente */
    display: flex; /* Habilita flexbox */
    align-items: center; /* Centra verticalmente el contenido */
    justify-content: center; /* Centra horizontalmente el contenido */
    text-decoration: none; /* Elimina subrayado del texto */
    font-family: "Product Sans", Arial, Helvetica, sans-serif;
    text-align: center;
}